angular.module('kno2.factories', []);

angular.module('kno2.login', ['kno2.factories'])
    .config(['$locationProvider', function($locationProvider){
        $locationProvider.hashPrefix('');
    }])
    .controller('LoginCtrl', ['$location', '$window', 'LocalStorageFactory', function ($location, $window, LocalStorageFactory) {
        var RETURN_URL_KEY = 'login.returnUrl',
            returnUrl = $location.path() || parseReturnUrl($window.location.href)

        if (returnUrl) {
            LocalStorageFactory.set(RETURN_URL_KEY, returnUrl);
            LocalStorageFactory.cookie.set(RETURN_URL_KEY, returnUrl, LocalStorageFactory.getDomain());
        }

        function parseReturnUrl(url) {
            if (!url) return;
            
            var queryString = url.split('?')[1],
                query = parseQueryString(queryString),
                aspNetReturnUrl = query && query['ReturnUrl'];

            if (!aspNetReturnUrl) return;

            return aspNetReturnUrl.split('#')[1];
        }

        function parseQueryString(queryString) {
            if (!queryString) return;

            var query = {},
                params = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

            for (var i = 0; i < params.length; i++) {
                var param = params[i].split('=');
                query[decodeURIComponent(param[0])] = decodeURIComponent(param[1] || '');
            }

            return query;
        }
    }
]);