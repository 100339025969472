import './vendor.js';
import './login/login.ctrl.js';
import './common/factories/localstorage.factory.js';

$(document).ready(function () {
    $('body').fadeIn(500);
});

$(document).ready(function () {
    window.onresize = function (event) {
        resizeContent();
    };

    window.kno2Login = function () {
        var ctrl = $('#UserName');
        if (ctrl) {
            ctrl.val(ctrl.val().replace(/\s+/, ''));
        }
        $('#loginForm').submit();
    };

    $('input').keypress(function (event) {
        var keyCode = event.keyCode || event.which;
        if (keyCode == 13) { kno2Login(); }
    });

    resizeContent();
});

function resizeContent() {
    var h = $(window).height();
    if (h > 500)
        $('#content').height(h);
}