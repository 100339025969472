// polyfills
import 'core-js/fn/promise';

// scripts
import autoScroll from 'dom-autoscroller';
import dragula from 'dragula';
import { saveAs } from 'file-saver';
import IScroll from 'iscroll';
import _ from 'lodash';
import moment from 'moment';
import Ladda from 'ladda';
import PNotify from 'pnotify/src/pnotify.js';
import 'pnotify/src/pnotify.buttons.js';
import Spinner from 'spin.js';
import UAParser from 'ua-parser-js';
import Clipboard from 'clipboard';

window.autoScroll = autoScroll;
window.dragula = dragula;
window.saveAs = saveAs;
window.IScroll = IScroll;
window._ = _;
window.moment = moment;
window.Ladda = Ladda;
window.PNotify = PNotify;
window.Spinner = Spinner;
window.UAParser = UAParser;
window.Clipboard = Clipboard;

// jquery and plugins
import './jquery';
import 'chosen-js';
import 'Flot/jquery.flot.js';
import 'Flot/jquery.flot.resize.js';
import 'Flot/jquery.flot.time.js';
import 'flot-aggregate';
import 'bootstrap/dist/js/bootstrap.js';
import 'jquery-validation';
import 'noty';
import 'signalr';
import 'jquery-sparkline'

// angular
import angular from 'angular';
window.angular = angular;
import 'angular-animate';
import 'angular-ui-bootstrap';
import 'angular-cache/dist/angular-cache.js';
import 'angular-chosen-localytics';
import 'angular-cookies';
import 'angular-http-batcher/dist/angular-http-batch.js';
import 'angular-loader';
import 'angular-sanitize';
import 'angular-spinner';
import 'angular-touch';
import 'angular-ui-utils/mask.js';
import 'ngclipboard';
import 'ng-file-upload';
import 'ng-idle';
import 'ng-iScroll';
import 'ng-tags-input';
import 'angular-flot/angular-flot.js';